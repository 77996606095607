<template>
  <div id="app">
    <div v-if="isMobile">
      <div>
        <keep-alive style="margin-bottom: 100px">
          <router-view @setMenuItemCount="setMenuItemCount"></router-view>
        </keep-alive>
      </div>
      <van-tabbar
        route
        v-model="mobile.active"
        @change="onMobileTabChange"
        style="z-index: 10"
      >
        <van-tabbar-item
          replace
          :to="item.url"
          icon="home-o"
          :badge="item.count === 0 ? '' : item.count"
          v-for="(item, index) in menu"
          :key="index"
        >
          <span>{{ item.name }}</span>
          <template #icon="props">
            <el-avatar fit="fill" :size="20" :src="item.icon"></el-avatar>
          </template>
        </van-tabbar-item>
      </van-tabbar>
    </div>
    <div v-else>
      <div class="header">
        <div class="header-container">
          <div class="header-left">
            <div style="display: flex; align-items: center">
              <!--            <el-avatar-->
              <!--              :size="30"-->
              <!--              :src="require('@/assets/favicon.png')"-->
              <!--            ></el-avatar>-->
              <span style="margin-left: 10px">easonhuang.net</span>
            </div>
          </div>
          <div class="header-right">
            <div class="header-menu">
              <el-menu
                :default-active="activeIndex"
                class="el-menu-demo"
                mode="horizontal"
                @select="handleSelect"
                background-color="#02132b"
                text-color="#fff"
                active-text-color="#fff"
              >
                <el-menu-item
                  v-for="(item, index) in menu"
                  :key="index"
                  :index="index.toString()"
                >
                  <el-badge
                    v-if="item.count !== 0 && item.count !== ''"
                    :value="item.count"
                    class="header-menu-item"
                  >
                    {{ item.name }}
                  </el-badge>
                  <div v-else class="header-menu-item">
                    {{ item.name }}
                  </div>
                </el-menu-item>
              </el-menu>
            </div>
            <div
              v-if="
                !weatherInfo.loading &&
                weatherInfo.cityName !== undefined &&
                weatherInfo.cityName !== null &&
                weatherInfo.cityName !== ''
              "
              class="weather"
            >
              <img class="weather-icon" :src="weatherInfo.icon" />
              <div class="weather-info">
                <div>
                  {{ weatherInfo.cityName }} {{ weatherInfo.temperature }}℃
                </div>
                <div>
                  {{ weatherInfo.airLevel }}
                </div>
              </div>
            </div>
            <div
              class="weather"
              v-else
              v-loading="weatherInfo.loading"
              element-loading-background="rgba(0, 0, 0, 0)"
            ></div>
          </div>
        </div>
      </div>
      <div class="body-container">
        <div class="card-container">
          <div class="card-body">
            <keep-alive>
              <router-view @setMenuItemCount="setMenuItemCount"></router-view>
            </keep-alive>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getWeather } from "@/api/api"
import { getBlogCount } from "@/api/blog"
import { getRepoCount } from "@/api/github"

export default {
  name: "App",
  components: {},
  data() {
    return {
      isMobile: this.$isMobile(),
      mobile: {
        activate: 0
      },
      activeIndex: "0",
      menu: [
        {
          name: "关于我",
          router: true,
          url: "/index",
          count: 0,
          icon: require("@/assets/profile.png")
        },
        {
          name: "Github",
          url: "/github",
          count: 0,
          icon: require("@/assets/github/github.png")
        },
        {
          name: "CSDN 博客",
          url: "/blog",
          count: 0,
          icon: require("@/assets/blog/csdn.png")
        }
      ],
      weatherInfo: {
        loading: true,
        cityName: "",
        temperature: "",
        airLevel: "",
        icon: require("@/assets/weather/qing.png")
      }
    }
  },
  mounted() {
    this.getBlogCount() // 获取博客博文数量
    this.getRepoCount() // 获取 Github 仓库 数量
    // PC 端访问
    if (!this.isMobile) {
      // 获取天气信息
      this.queryWeatherInfo()
    }
  },
  methods: {
    onMobileTabChange(index) {
      let menuItem = this.menu[index]
      if (menuItem.router === false) {
        // 跳转外部页面
        window.location.href = menuItem.url
      }
      // else {
      //   // 路由跳转，不做处理，由组件处理
      //   // this.$router.push({ path: menuItem.url })
      // }
    },
    getBlogCount() {
      getBlogCount().then((res) => {
        let count = res.data.total
        this.setMenuItemCount({ itemIndex: 2, count })
      })
    },
    getRepoCount() {
      getRepoCount().then((res) => {
        let count = res.data.total
        this.setMenuItemCount({ itemIndex: 1, count })
      })
    },
    setMenuItemCount({ itemIndex, count }) {
      this.menu[itemIndex].count = count
    },
    handleSelect(key) {
      let menuItem = this.menu[key]
      if (this.activeIndex === key) {
        return
      }
      this.activeIndex = key
      if (menuItem.router === false) {
        // 跳转外部页面
        window.location.href = menuItem.url
      } else {
        // 路由跳转
        this.$router.push({ path: menuItem.url })
      }
    },
    queryWeatherInfo() {
      this.weatherInfo.loading = true
      let int = setTimeout(() => {
        this.weatherInfo.loading = false
      }, 10000)
      getWeather().then((res) => {
        this.weatherInfo.cityName = res.city
        this.weatherInfo.temperature = res.tem
        this.weatherInfo.airLevel = res.air_level
        this.weatherInfo.icon = require("@/assets/weather/" +
          res.wea_img +
          ".png")
        if (
          this.weatherInfo.cityName !== "" ||
          this.weatherInfo.temperature !== "" ||
          this.weatherInfo.airLevel !== ""
        ) {
          this.weatherInfo.loading = false
          clearTimeout(int)
        }
      })
    }
  }
}
</script>

<style>
#app {
  font-family: Arial, Noto Sans SC, sans-serif;
  width: 100%;
  height: 100%;
}

.el-menu-item {
  height: 50px !important;
  line-height: 50px !important;
}
.el-badge__content {
  margin-top: 10px !important;
  margin-right: -8px !important;
  z-index: 999;
}
</style>

<style scoped>
.header-menu-item {
}

.header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #02132b;
}

.header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: white;
  width: 70%;
  height: 50px;
  /*padding: 5px;*/
}

.header-left {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 16px;
}

.header-right {
  display: flex;
  align-items: center;
  font-size: 12px;
}

.header-menu {
  display: flex;
  font-size: 14px;
  margin-right: 10px;
}

.body-container {
  position: absolute;
  top: 50px;
  left: 0px;
  width: 100%;
  height: calc(100% - 60px);
}

.card-container {
  height: calc(100%);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow-y: auto;
}

.card-body {
  width: 70%;
  height: calc(100%);
}

.weather {
  display: flex;
  height: 50px;
  width: 100px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.weather-icon {
  width: 22px;
  height: 22px;
}

.weather-info {
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

p {
  margin: 0 !important;
}
</style>
