import request from "@/utils/http"

/**
 * 查询仓库总数
 */
export function getRepoCount() {
  return request({
    url: "/api/github/getRepoCount",
    method: "get"
  })
}

/**
 * 查询 Github 置顶仓库列表
 */
export function getRecommendRepoList() {
  return request({
    url: "/api/github/getRecommendRepoList",
    method: "get"
  })
}

/**
 * 查询 Github 仓库列表
 *
 * &orderby=blog(按最后发布时间由近到远降序)/ViewCount(按照访问量从多到少降序)
 */
export function getRepoList(query) {
  return request({
    url: "/api/github/getRepoList",
    method: "get",
    params: {
      page: query.page ? query.page : 1,
      size: query.pageSize ? query.pageSize : 50,
      orderBy: query.orderBy ? query.orderBy : ""
    }
  })
}
