var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"app"}},[(_vm.isMobile)?_c('div',[_c('div',[_c('keep-alive',{staticStyle:{"margin-bottom":"100px"}},[_c('router-view',{on:{"setMenuItemCount":_vm.setMenuItemCount}})],1)],1),_c('van-tabbar',{staticStyle:{"z-index":"10"},attrs:{"route":""},on:{"change":_vm.onMobileTabChange},model:{value:(_vm.mobile.active),callback:function ($$v) {_vm.$set(_vm.mobile, "active", $$v)},expression:"mobile.active"}},_vm._l((_vm.menu),function(item,index){return _c('van-tabbar-item',{key:index,attrs:{"replace":"","to":item.url,"icon":"home-o","badge":item.count === 0 ? '' : item.count},scopedSlots:_vm._u([{key:"icon",fn:function(props){return [_c('el-avatar',{attrs:{"fit":"fill","size":20,"src":item.icon}})]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.name))])])}),1)],1):_c('div',[_c('div',{staticClass:"header"},[_c('div',{staticClass:"header-container"},[_vm._m(0),_c('div',{staticClass:"header-right"},[_c('div',{staticClass:"header-menu"},[_c('el-menu',{staticClass:"el-menu-demo",attrs:{"default-active":_vm.activeIndex,"mode":"horizontal","background-color":"#02132b","text-color":"#fff","active-text-color":"#fff"},on:{"select":_vm.handleSelect}},_vm._l((_vm.menu),function(item,index){return _c('el-menu-item',{key:index,attrs:{"index":index.toString()}},[(item.count !== 0 && item.count !== '')?_c('el-badge',{staticClass:"header-menu-item",attrs:{"value":item.count}},[_vm._v(" "+_vm._s(item.name)+" ")]):_c('div',{staticClass:"header-menu-item"},[_vm._v(" "+_vm._s(item.name)+" ")])],1)}),1)],1),(
              !_vm.weatherInfo.loading &&
              _vm.weatherInfo.cityName !== undefined &&
              _vm.weatherInfo.cityName !== null &&
              _vm.weatherInfo.cityName !== ''
            )?_c('div',{staticClass:"weather"},[_c('img',{staticClass:"weather-icon",attrs:{"src":_vm.weatherInfo.icon}}),_c('div',{staticClass:"weather-info"},[_c('div',[_vm._v(" "+_vm._s(_vm.weatherInfo.cityName)+" "+_vm._s(_vm.weatherInfo.temperature)+"℃ ")]),_c('div',[_vm._v(" "+_vm._s(_vm.weatherInfo.airLevel)+" ")])])]):_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.weatherInfo.loading),expression:"weatherInfo.loading"}],staticClass:"weather",attrs:{"element-loading-background":"rgba(0, 0, 0, 0)"}})])])]),_c('div',{staticClass:"body-container"},[_c('div',{staticClass:"card-container"},[_c('div',{staticClass:"card-body"},[_c('keep-alive',[_c('router-view',{on:{"setMenuItemCount":_vm.setMenuItemCount}})],1)],1)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"header-left"},[_c('div',{staticStyle:{"display":"flex","align-items":"center"}},[_c('span',{staticStyle:{"margin-left":"10px"}},[_vm._v("easonhuang.net")])])])
}]

export { render, staticRenderFns }