import request from "@/utils/http"

/**
 * 查询CSDN博文总数
 */
export function getBlogCount() {
  return request({
    url: "/api/blog/getBlogCount",
    method: "get"
  })
}

/**
 * 查询CSDN置顶博文列表
 */
export function getRecommendBlogList() {
  return request({
    url: "/api/blog/getRecommendBlogList",
    method: "get"
  })
}

/**
 * 查询CSDN博文列表
 *
 * page=第几页
 * &size=每页大小
 * &businessType=blog
 * &orderby=blog(按最后发布时间由近到远降序)/ViewCount(按照访问量从多到少降序)
 * &noMore=false
 * &year=YYYY(年份,可选)
 * &month==MM(月份,可选)
 * &username=用户ID
 */
export function getBlogList(query) {
  return request({
    url: "/api/blog/getBlogList",
    method: "get",
    params: {
      page: query.page ? query.page : 1,
      size: query.pageSize ? query.pageSize : 10,
      businessType: "blog",
      orderby: query.orderby ? query.orderby : "",
      noMore: false,
      year: query.year ? query.year : "",
      month: query.month ? query.month : ""
    }
  })
}

/**
 * 查询CSDN博文分月统计
 * ?username=用户ID
 */
export function getBlogByMonthInfo() {
  return request({
    url: "/api/blog/getBlogByMonthInfo",
    method: "get"
  })
}
