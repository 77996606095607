import requestApi from "../utils/httpApi"
import requestHtml from "../utils/httpHtml"

export function getWeather() {
  return requestApi({
    url: "https://v0.yiketianqi.com/api?unescape=1&version=v61&appid=43656176&appsecret=I42og6Lm",
    method: "get"
  })
}

/**
 * 获取HTML内容
 */
export function getHtmlPageContent({ url }) {
  return requestHtml({
    url: url,
    method: "get"
  })
}
