import Vue from "vue"
import VueRouter from "vue-router"
Vue.use(VueRouter)

/**
 * PC 端路由
 */
const pcRoutes = [
  {
    path: "/index",
    component: () => import("../components/my/myPage")
  },
  {
    path: "/github",
    component: () => import("../components/github/githubPage")
  },
  {
    path: "/blog",
    component: () => import("../components/blog/blogPage")
  },
  {
    path: "/404",
    component: () => import("../components/notFoundPage")
  },
  {
    path: "/",
    redirect: "/index" //默认显示
  }
]

/**
 * APP 端路由
 */
const appRoutes = [
  {
    path: "/index",
    component: () => import("../components/my/myPage")
  },
  {
    path: "/blog",
    component: () => import("../components/blog/blogPage")
  },
  {
    path: "/github",
    component: () => import("../components/github/githubPage")
  },
  {
    path: "/",
    redirect: "/index" //默认显示
  }
]

// 判断是什么平台访问
const isMobile =
  /iphone|ipod|android.*mobile|windows.*phone|blackberry.*mobile/i.test(
    window.navigator.userAgent.toLowerCase()
  )

const router = new VueRouter({
  routes: isMobile ? appRoutes : pcRoutes
})

router.beforeEach((to, form, next) => {
  if (to.matched.length === 0) {
    window.location.href = "/404.html"
    // next({ path: "/404" })
  }
  next()
})

export default router
