import axios from "axios"
import { Notification, MessageBox, Message } from "element-ui"

axios.defaults.headers["Content-Type"] = "application/json;charset=utf-8"

// 创建axios实例
const http = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  // baseURL: "https://easonhuang.net",
  // 超时
  timeout: 600000,
  responseType: "json",
  withCredentials: false // 是否允许跨域请求时发送Cookie
})

// request拦截器
http.interceptors.request.use(
  (config) => {
    // 允许跨域请求的地址 (*代表所有地址)
    config.headers["Access-Control-Allow-Origin"] = "*" //重点
    // 允许跨域请求携带cookie
    config.headers["Access-Control-Allow-Credentials"] = "true" //重点
    // get请求映射params参数
    if (config.method === "get" && config.params) {
      let url = config.url + "?"
      for (const propName of Object.keys(config.params)) {
        const value = config.params[propName]
        var part = encodeURIComponent(propName) + "="
        if (value !== null && typeof value !== "undefined") {
          if (typeof value === "object") {
            for (const key of Object.keys(value)) {
              let params = propName + "[" + key + "]"
              var subPart = encodeURIComponent(params) + "="
              url += subPart + encodeURIComponent(value[key]) + "&"
            }
          } else {
            url += part + encodeURIComponent(value) + "&"
          }
        }
      }
      url = url.slice(0, -1)
      config.params = {}
      config.url = url
    }

    // console.log(config)
    return config
  },
  (error) => {
    Promise.reject(error)
  }
)

// 响应拦截器
http.interceptors.response.use(
  (res) => {
    const code = res.data.code || 200
    if (code !== 200) {
      Notification.error({
        title: "数据获取错误，请稍后再试"
      })
      return Promise.reject("error")
    } else {
      return res.data
    }
  },
  (error) => {
    Message({
      message: "数据获取错误，请稍后再试",
      type: "error",
      duration: 5 * 1000
    })
    return Promise.reject(error)
  }
)

export default http
