import Vue from "vue"
import App from "./App.vue"

// XML TO JSON
// import x2js from "x2js"
// Vue.prototype.$x2js = new x2js()

/**
 * 判断是否是手机端访问
 * @returns {boolean}
 */
Vue.prototype.$isMobile = function () {
  return /iphone|ipod|android.*mobile|windows.*phone|blackberry.*mobile/i.test(
    window.navigator.userAgent.toLowerCase()
  )
}

// Element UI
import elementui from "element-ui"
import "element-ui/lib/theme-chalk/reset.css"
import "element-ui/lib/theme-chalk/index.css"
Vue.use(elementui)

// Vant
import "vant/lib/index.css"

// Tabbar 标签栏
import { Tabbar, TabbarItem } from "vant"
Vue.use(Tabbar)
Vue.use(TabbarItem)

// Swipe 轮播
import { Swipe, SwipeItem } from "vant"
Vue.use(Swipe)
Vue.use(SwipeItem)

// Loading 加载
import { Loading } from "vant"
Vue.use(Loading)

// NavBar 导航栏
import { NavBar } from "vant"
Vue.use(NavBar)

// Icon 图标
import { Icon } from "vant"
Vue.use(Icon)

// Cell 单元格
import { Cell, CellGroup } from "vant"
Vue.use(Cell)
Vue.use(CellGroup)

// Popup 弹出层
import { Popup } from "vant"
Vue.use(Popup)

// Radio 单选框
import { RadioGroup, Radio } from "vant"
Vue.use(Radio)
Vue.use(RadioGroup)

// Cascader 级联选择
import { Cascader } from "vant"
Vue.use(Cascader)

// Field 输入框
import { Field } from "vant"
Vue.use(Field)

// Vue router
import router from "./router/router"

Vue.config.productionTip = false

new Vue({
  router,
  render: (h) => h(App)
}).$mount("#app")
